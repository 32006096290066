.ant-layout-sider {
  background-color: #eeeeee;
  .ant-menu, .ant-menu-item, .ant-menu-tree-arrow {
    color: #666;
  }
}

.ant-card-extra {
  .ant-form-item {
    margin-bottom: 0;
  }
}